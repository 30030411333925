import React from 'react';
import styled from 'styled-components';
import { colors } from '../styleConstants';
import FlexContainer from '../elements/FlexContainer';
import useInView from '../hooks/useInView';

import FeatureBox from './FeatureBox';

const SectionFeatures = (props) => {
  const isVisible = useInView("features");

  return (
    <Container
      id="features"
      flexDirection="column"
      alignItems="center"
      className={`animate-on-scroll ${isVisible ? 'visible' : ''}`}
    >
      <Caption>Full-Service Design and Development Agency</Caption>
      <Header>Take the leap from dream to reality</Header>
      <FeatureRow justify="center">
        <FeatureBox
          icon={`${process.env.PUBLIC_URL}/images/icons/003-layers.png`}
          header="UI/UX Design"
          text="Beautiful, memorable, and user-friendly interfaces that are as unique as your brand."
        />
        <FeatureBox
          icon={`${process.env.PUBLIC_URL}/images/icons/016-design.png`}
          header="Web Development"
          text="Fully custom web applications for an intuitive and immersive user experience."
        />
        <FeatureBox
          icon={`${process.env.PUBLIC_URL}/images/icons/028-website.png`}
          header="Mobile Development"
          text="Mobile-friendly apps built in tandem with your web
          app and compatible across platforms."
        />
      </FeatureRow>
      <AdditionalServicesContainer flexDirection="column" justify="center">
        <ServicesHeader>Additional Services</ServicesHeader>
        <Grid
          flexDirection="column"
          wrap="wrap"
          alignItems="flex-start"
          alignContent="center"
        >
          <ServiceItem>
            <Img src="/images/icons/ProjectManagement.svg" />
            <ServiceText>Project Management</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Img src="/images/icons/Server.svg" />
            <ServiceText>Server Infrastructure</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Img src="/images/icons/Dashboard.svg" />
            <ServiceText>Dashboards and Reporting</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Img src="/images/icons/CMS.svg" />
            <ServiceText>Custom Admin Panels</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Img src="/images/icons/DatabaseManagement.svg" />
            <ServiceText>Database Management</ServiceText>
          </ServiceItem>
          <ServiceItem>
            <Img src="/images/icons/Maintenance.svg" />
            <ServiceText>Long-Term Maintenance</ServiceText>
          </ServiceItem>
        </Grid>
      </AdditionalServicesContainer>
    </Container>
  )
};

const Container = styled(FlexContainer)`

`
const FeatureRow = styled(FlexContainer)`
  >:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    >:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 479px) {
    flex-direction: column;
    > * {
      margin-bottom: 24px;
    }
    >:not(:last-child) {
      margin-right: 0;
    }
  }
`

const Header = styled.h2`
  color: #fff;
  text-align: center;
  margin-bottom: 60px;

  @media (max-width: 479px) {
    width: 80%;
  }
`

const Caption = styled.h3`
  color: ${colors.mainYellow};
`

const ServicesHeader = styled.h5`
  color: ${colors.mainYellow};
  text-transform: uppercase;
  text-align: center;
  font-weight: 800;

  @media (max-width: 479px) {
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 16px;
  }
`

const AdditionalServicesContainer = styled(FlexContainer)`
  width: 100%;
  margin-top: 30px;
`
const Grid = styled(FlexContainer)`
  max-height: 100px;
  width: 100%;
  margin-top: 12px;
  > * {
    margin: 0 36px 12px 0;
  }

  @media (max-width: 479px) {
    max-height: 300px;
    align-content: center;
    > * {
      margin-right: 0;
    }
  }
`

const Img = styled.img`
  height: 20px;
`

const ServiceItem = styled(FlexContainer)`
  > :first-child {
    margin-right: 12px;
  }
`

const ServiceText = styled.p`
  color: #fff;
  font-size: 15px;
`

export default SectionFeatures;
