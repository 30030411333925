import { useState, useEffect } from "react";

const useInView = (elementId, threshold = 0.3) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            { threshold }
        );

        const element = document.getElementById(elementId);
        if (element) observer.observe(element);

        return () => observer.disconnect();
    }, [elementId, threshold]);

    return isVisible;
};

export default useInView;